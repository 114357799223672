<template>
    <div class="footer">
        <div class="upper">
            <img class="logo" :src="require('@/assets/img/logo-white.png')" alt="logo">
        
            <div class="select-container">
                <!-- <div class="select language">
                    <p>Language - </p>
                    <select
                        v-model="language"
                        @click="handleSetActiveLanguage"
                    >
                        <option
                            :value="language.id"
                            v-for="language in languages"
                            :key="language.id"
                        >
                            {{ language.lang }}
                        </option>
                    </select>
                </div> -->

                <div class="select">
                    <p>Currency - </p>
                    <select :model="currencies">
                        <option
                            :value="currency.text"
                            v-for="currency in currencies"
                            :key="currency.id"
                        >
                            {{ currency.text }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="socials">
                <img class="fb" :src="require('@/assets/img/facebook.svg')" alt="facebook" @click="goTo('https://www.facebook.com/Findlinc-107871831717913')">
                <!-- <img :src="require('@/assets/img/instagram.svg')" alt="instagram" @click="goTo('https://www.instagram.com/findlinccard/')"> -->
            </div>
        </div>

        <div class="lower">
            <p>Copyright © 2021 FindLinc. All Rights Reserved.</p>
            <div class="links">
                <router-link to="/terms" target="_blank">Terms and condition</router-link>
                <router-link to="/policy" target="_blank">Privacy Policy</router-link>
                <router-link to="/cookies" target="_blank">Cookies</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    data() {
        return {
            // selectedLanguage: "en",
            // languages: [
            //     { lang: "English", id: "en" },
            //     { lang: "Chinese", id: "cn" }
            // ],
            currencies: [
                { text: "TWD", id: "twd" },
                // { text: "USD", id: "usd" }
            ]
        }
    },

    computed: {
        ...mapFields(['language'])
    },

    methods: {
        // handleSetActiveLanguage (event) {
        //     const id = event.target.value
            
        //     this.$i18n.locale = id
		// 	Cookies.set('locale', id)

        //     this.$store.commit('SET_LANGUAGE', id)
        // }
        goTo (url) {
            window.open(url, '_blank');
        }
    },
}
</script>

<style lang="scss" scoped>
.footer {
    justify-content: center;
    background-color: #00AEEF;

    .upper {
        display: flex;
        align-items: center;
        padding: 5px 30px;
        border-bottom: 1px solid #ffffff;

        .logo {
            cursor: pointer;
            width: 90px;
            height: 45px;
            margin-right: 15px;
        }

        .select-container {
            display: flex;
            flex: 1;

            .select {
                display: flex;

                &.language {
                    margin-right: 35px;
                }

                p {
                    font-family: 'Crimson Pro', sans-serif;
                    font-size: 18px;
                    color: #ffffff;
                }

                select {
                    cursor: pointer;
                    border: transparent;
                    background-color: transparent;
                    font-family: 'Crimson Pro', sans-serif;
                    font-size: 18px;
                    color: #ffffff;

                    option {
                        font-size: 14px;
                    }
                }
            }

        }

        .socials {
            img {
                cursor: pointer;
                height: 25px;
                width: 25px;

                &.fb {
                    margin-right: 15px;
                }
            }
        }
    }
    
    .lower {
        display: flex;
        align-items: center;
        padding: 10px 30px;
        font-size: 12px;
        color: #ffffff;
        flex-direction: column-reverse;

        @include desktop {
            flex-direction: row;
        }

        p {
            flex: 1;
        }

        .links {
            margin-bottom: 10px;

            @include desktop {
                margin-bottom: 0px;
            }

            a {
                cursor: pointer;
                color: #ffffff;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>